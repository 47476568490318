
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { IContact, defaultContact, IMainInformation, defaultMainInformation } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { styled } from '@mui/system';

import { isFalsy } from 'utility-types';
import { globalConfig } from 'config';
import { Box, Grid, Typography, Container, Card, IconButton,TextField,Button } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function Contact() {

    const { getContact, getMainInformations } = useMainInformation();

    const {data: contacts} = useQuery<IContact[]>( ['Contact'], () => getContact());
    const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations ());

  
  const [contact, setcontact] = useState<IContact>(defaultContact);
  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);

    useEffect(() => {
      
        if(!isFalsy(contacts) && contacts?.length>0)
            setcontact(contacts[0]);
      }, [contacts]);

      useEffect(() => {
      
        if(!isFalsy(mainInformations) && mainInformations?.length>0)
            setMainInformation(mainInformations[0]);
      }, [mainInformations]);

      const CircleIcon = styled('div')({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        backgroundColor: '#ADADAC',
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: '#3464AE',
        },

        
      });

      const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: ''
      });
    
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
      
      const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Ici, vous pouvez ajouter votre logique pour envoyer les données du formulaire
        console.log(formData);
        // Réinitialise le formulaire après soumission
        setFormData({ firstName: '', lastName: '', email: '', subject: '', message: '' });
      };
      

  return (
    <Box >
        
        <Grid container spacing={1}>
    <Grid item xs={12} md={12} style={{ textAlign: 'center', position: 'relative' }}>
    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
      <img src={`${globalConfig.get().apiUrl}/download/${contact.image1_Url}`} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
    </div>
        <Typography variant='h4' sx={{ fontFamily: 'Poppins', marginTop: '-170px', color: '#fff', zIndex: 1, position: 'relative' }}>{contact.titre}</Typography>
    </Grid>
</Grid>

<Container maxWidth='xl' sx={{ marginTop:'200px'}}>
  <Grid container spacing={1} style={{ padding: '20px', position: 'relative' }}>
    <Grid item xs={12} md={4} style={{ textAlign: 'center', padding: '20px', position: 'relative'}}>
      <CircleIcon style={{ position: 'absolute', top: '-20px', left: 'calc(50% - 35px)' }}>
        <LocationOnIcon sx={{ color: '#fff' }} />
      </CircleIcon>
      <Card style={{ backgroundColor: '#f9f9f9', padding: '20px', height: '200px' }}>
        <Typography variant='h6' sx={{ fontFamily: 'Poppins', fontWeight: 'bold', marginTop: '30px' }}>Adresse</Typography>
        <Typography variant='h6' sx={{ fontFamily: 'Poppins' }}>{mainInformation.localisation}</Typography>
      </Card>

    <Typography sx={{ marginTop:'10px'}}> </Typography>

    </Grid>



    <Grid item xs={12} md={4} style={{ textAlign: 'center', padding: '20px', position: 'relative' }}>
      <CircleIcon style={{ position: 'absolute', top: '-20px', left: 'calc(50% - 35px)' }}>
        <PhoneIcon sx={{ color: '#fff' }} />
      </CircleIcon>
      <Card style={{ backgroundColor: '#f9f9f9', padding: '20px', height: '200px' }}>
        <Typography variant='h6' sx={{ fontFamily: 'Poppins', fontWeight: 'bold', marginTop: '30px' }}>Téléphone</Typography>
        <Typography variant='h6' sx={{ fontFamily: 'Poppins' }}>{mainInformation.portable}</Typography>
      </Card>

    <Typography sx={{ marginTop:'10px'}}> </Typography>

    </Grid>


    <Grid item xs={12} md={4} style={{ textAlign: 'center', padding: '20px', position: 'relative' }}>
      <CircleIcon style={{ position: 'absolute', top: '-20px', left: 'calc(50% - 35px)' }}>
        <EmailIcon sx={{ color: '#fff' }} />
      </CircleIcon>
      <Card style={{ backgroundColor: '#f9f9f9', padding: '20px', height: '200px' }}>
        <Typography variant='h6' sx={{ fontFamily: 'Poppins', fontWeight: 'bold', marginTop: '30px' }}>E-Mail</Typography>
        <Typography variant='h6' sx={{ fontFamily: 'Poppins' }}>{mainInformation.email}</Typography>
      </Card>
    </Grid>
  </Grid>
</Container>


<Container maxWidth='xl' style={{ marginTop: '50px' }}>
      <Typography variant="h5" gutterBottom>
        Formulaire de Contact
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Nom"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Prénom"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="E-mail"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Objet"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              label="Message"
              name="message"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
          <Button
  type="submit"
  variant="contained"
  color="primary"
  sx={{
    height: '50px',
    width: '20%',
    marginTop: '10px',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  }}
>
  Envoyer
</Button>
          </Grid>
        </Grid>
      </form>
    </Container>

         <Typography sx={{ marginTop:'40px'}}> {/* Adjust marginTop */}
        
      </Typography>

      


      <Grid container spacing={1}>
  <Grid item xs={12} md={12} style={{ textAlign: 'center', position: 'relative' }}>
    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
      <img src={`${globalConfig.get().apiUrl}/download/${contact.image_Url}`} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
    </div>
    <Typography variant='h5' sx={{ fontFamily: 'Poppins', marginTop: '-185px', color: '#fff', zIndex: 1, position: 'relative',  '@media (max-width: 600px)': {width: '100%',marginTop: '-225px' } }}>{contact.titreSecondaire}</Typography>
    <Button
      variant="contained"
      color="primary"
      href="/contact"
      endIcon={<KeyboardArrowRightIcon />}
      sx={{marginTop: '20px',height: '50px',width:'20%',  '@media (max-width: 600px)': {width: '50%',marginTop: '15px' }}}
    >
      CONTACTEZ-NOUS
    </Button>
  </Grid>
</Grid>



<Typography sx={{ marginTop:'130px'}}> {/* Adjust marginTop */}
        
      </Typography>
    </Box>       
    
  )
}

export default Contact
