import { Container, Grid, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from 'react';
import useMainInformation from 'features/setup/services/MainInformation';
import { isFalsy } from 'utility-types';
import { globalConfig } from 'config';
import { ISpecialyty, defaultSpeciality } from "./models/MainInformation";
import { useQuery } from 'react-query';

const Speciality = () => {

    const { getSpeciality } = useMainInformation();

    const { data: Specialities } = useQuery<ISpecialyty[]>(['Specialyty'], () => getSpeciality());

    const [speciality, setSpeciality] = useState<ISpecialyty>(defaultSpeciality);

    useEffect(() => {
        if (!isFalsy(Specialities) && Specialities?.length > 0)
            setSpeciality(Specialities[0]);
    }, [Specialities]);

    return (
        <Container maxWidth='xl' sx={{ marginTop:'50px'}}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <Typography variant='h4' sx={{ fontFamily: 'Poppins', marginTop:'30px' }}> {speciality.titrePrincipal} </Typography>
                    <Typography variant='h6' sx={{ fontFamily: 'Poppins', marginTop:'50px', textAlign:'justify' }}> {speciality.titreSecondaire} </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* Affichage de la vidéo YouTube */}
                    <iframe title="YouTube Video" width="100%" height="500px" src={`https://www.youtube.com/embed/${speciality.videoId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ marginTop:'50px'}}>
                {/* Affichage des spécialités avec pourcentage et remplissage */}
                {[{ name: speciality.specialite1, percentage: 98 }, { name: speciality.specialite2, percentage: 95 }, { name: speciality.specialite3, percentage: 90 }, { name: speciality.specialite4, percentage: 95 }, { name: speciality.specialite5, percentage: 80 }].map((specialite, index) => (
                    <Grid key={index} item xs={12} md={4} sx={{ marginTop: '30px' }}>
                        <Typography variant='h6' sx={{ fontFamily: 'Poppins', display: 'flex', justifyContent: 'space-between' }}> {specialite.name} {specialite.percentage}%</Typography>
                        <Box
                            sx={{
                                width: '100%',
                                height: '10px',
                                backgroundColor: '#f9f9f9', // Couleur vide
                                borderRadius: '5px',
                                marginTop: '10px',
                                overflow: 'hidden',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: '#3464AE', // Couleur de remplissage
                                    borderRadius: '5px',
                                    marginLeft: `-${100 - specialite.percentage}%`, // Pourcentage restant
                                }}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Typography sx={{ marginTop:'50px'}}> {/* Adjust marginTop */}
          
        </Typography>
        </Container>
    )
}

export default Speciality;
