import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Formulaire = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Ici, vous pouvez ajouter votre logique pour envoyer les données du formulaire
    console.log(formData);
    // Réinitialise le formulaire après soumission
    setFormData({ firstName: '', lastName: '', email: '', subject: '', message: '' });
  };

  return (
    <Container maxWidth="xl" sx={{ marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom sx={{ marginBottom: '20px', textAlign:'center', fontWeight:'700' }}>
        {t('FORMULAIRE DE CONTACT')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={t('Nom')}
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={t('Prénom')}
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={t('E-mail')}
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={t('Objet')}
              name="subject"
              value={formData.subject}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('Message')}
              name="message"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                height: '50px',
                width: '20%',
                marginTop: '10px',
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
            >
              {t('Envoyer')}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Typography sx={{ marginTop:'30px'}}> {/* Adjust marginTop */}
              
              </Typography>
    </Container>
  );
};

export default Formulaire;
