import React, { useState } from 'react';
import { Box, Typography, Button, Stack, Card, CardContent, Link, Grid, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { IServices } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { globalConfig } from 'config';
import { useNavigate } from 'react-router-dom';

const NewsService = ({ nouveaute }: { nouveaute: IServices }) => {
  return (
    <Card
      sx={{
        mt: 2,
        width: '100%',
        
        borderRadius: '2%',
      }}
    >
      
        <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold',color:'#3464AE' }}>{nouveaute.titrePrincipal}</Typography>
        <hr />
        <Box
        sx={{
          flex: 1,
          backgroundImage: `url('${globalConfig.get().apiUrl}/download/${nouveaute.image_Url}')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '200px',
          borderRadius: '2%',
          
        }}
      />
     
     
    </Card>
  );
};

const Service = () => {
  const { t } = useTranslation();
  const { getServices} = useMainInformation();
  const { data: nouveautes } = useQuery<IServices[]>(['Services'], () => getServices());
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();

  const handleSeeMore = () => {
    navigate('/services');
  };

  const servicesToDisplay = showAll ? nouveautes : (nouveautes || []).slice(0, 4);

  return (
    <Box bgcolor="#F7F7F7" color="back" py={0.25} px={2} textAlign="center" mt={5}>
      <Typography sx={{ marginTop:'50px'}}> </Typography>

       <Typography variant="h4" sx={{ fontFamily: 'Poppins', marginTop:'15px', fontWeight: 'bold' }}>
        {t('NOS SERVICES')}
      </Typography>

      <Typography sx={{ marginTop:'50px'}}> </Typography>

      <Grid container spacing={2}>
        {servicesToDisplay.map((nouveaute, idx) => (
          <Grid item xs={12} sm={6} md={3} key={`news_${idx}`}>
            <NewsService nouveaute={nouveaute} />
          </Grid>
        ))}
      </Grid>
      <Typography sx={{marginTop:'30px'}}> </Typography>
      {nouveautes && nouveautes.length > 2 && (
        <><Button variant="outlined" onClick={handleSeeMore} sx={{ mt: 5, fontFamily: 'Poppins', width: '350px', color:'white', background:'#3464AE', height:'50px',borderRadius:'8px',
        '&:hover': {
          backgroundColor: '#ADADAC',
        }, }}>
          Voir plus
        </Button><Typography sx={{marginTop:'30px'}}> </Typography></>
      )}
    </Box>
  );
};

export default Service;
