import { Box, Container, Grid, Typography, Stack ,Button} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { typographyBigGroupBoxStyling, typographyGroupBoxStyling, typographySmallHandWriting } from 'themes/commonStyles';
import {  IContact, IGalery,  defaultContact } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { useQuery } from 'react-query';
import { globalConfig } from 'config';
import { isFalsy } from 'utility-types';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';



const Galery = () => {
  const { t, i18n } = useTranslation();
  const {getContact,getGalery  } = useMainInformation();
    const { data: galeries } = useQuery<IGalery[]>(['Galery'], () => getGalery ());
    const [galerie, setGalery] = useState<IGalery[]>([]);
   
    const {data: contacts} = useQuery<IContact[]>( ['Contact'], () => getContact());
    const [contact, setcontact] = useState<IContact>(defaultContact);

    useEffect(() => {
        setGalery(galeries  || []);
    }, [galeries ]);

    useEffect(() => {
      
        if(!isFalsy(contacts) && contacts?.length>0)
            setcontact(contacts[0]);
      }, [contacts]);

    

  return (
    <Box>
        <Grid container spacing={1}>
    <Grid item xs={12} md={12} style={{ textAlign: 'center', position: 'relative' }}>
    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
      <img src={`${globalConfig.get().apiUrl}/download/${contact.image1_Url}`} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
    </div>
        <Typography variant='h4' sx={{ fontFamily: 'Poppins', marginTop: '-170px', color: '#fff', zIndex: 1, position: 'relative' }}>{contact.titreGalerie}</Typography>
    </Grid>
</Grid>


      
<Stack flexDirection='column' marginTop='150px'>
            <Grid container spacing={1}>
                {galerie && galerie.map((galery, index) => (
                    <Grid item key={`photo-${index}`} xs={12} md={3} sx={{ width: '100%' }}>
                        <Box 
                            sx={{
                                position: 'relative',
                                overflow: 'hidden',
                                borderRadius: '2%',
                                '&:hover img': {
                                    transform: 'scale(1.1)',
                                },
                                '&:hover::after': {
                                    opacity: 1,
                                },
                                '::after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    opacity: 0,
                                    transition: 'opacity 0.3s ease',
                                    borderRadius: '2%',
                                },
                            }}
                        >
                            <img
                                src={`${globalConfig.get().apiUrl}/download/${galery.image_Url}`}
                                alt="..."
                                style={{ 
                                    width: '100%', 
                                    height: '300px', 
                                    objectFit: 'cover', 
                                    transition: 'transform 0.3s ease',
                                    borderRadius: '2%',
                                }}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    
                    <Typography sx={{marginTop:'50px'}}></Typography>




                    <Grid container spacing={1}>
    <Grid item xs={12} md={12} style={{ textAlign: 'center', position: 'relative' }}>
      <div style={{ position: 'relative', width: '100%', height: '300px' }}>
        <img src={`${globalConfig.get().apiUrl}/download/${contact.image_Url}`} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
      </div>
      <Typography variant='h5' sx={{ fontFamily: 'Poppins', marginTop: '-185px', color: '#fff', zIndex: 1, position: 'relative',  '@media (max-width: 600px)': {width: '100%',marginTop: '-225px' } }}>{contact.titreSecondaire}</Typography>
      <Button
        variant="contained"
        color="primary"
        href="/contact"
        endIcon={<KeyboardArrowRightIcon />}
        sx={{marginTop: '20px',height: '50px',width:'20%',  '@media (max-width: 600px)': {width: '50%',marginTop: '15px' }}}
      >
        CONTACTEZ-NOUS
      </Button>
    </Grid>
  </Grid>
  
  
  
  <Typography sx={{ marginTop:'130px'}}> {/* Adjust marginTop */}
          
        </Typography>

    </Box>
  )
}

export default Galery
