import React, { useState } from 'react';
import { Box, Typography, Button, Stack, Card, CardContent, Link, Grid, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ISolutions } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { globalConfig } from 'config';
import { useNavigate } from 'react-router-dom';

const NewSolution = ({ nouveaute }: { nouveaute: ISolutions}) => {
  return (
    <Card
      sx={{
        mt: 2,
        width: '100%',
        
        borderRadius: '2%',
      }}
    >
      <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color:'#3464AE' }}>{nouveaute.titrePrincipal}</Typography>
                    <hr />
                    <Typography variant="h6" sx={{ fontFamily: 'Poppins', textAlign:'justify' }}>{nouveaute.description}</Typography>

                </div>
             
            </CardContent>
       
     
     
    </Card>
  );
};

const Solution = () => {
  const { t } = useTranslation();
  const { getSolutions} = useMainInformation();
  const { data: nouveautes } = useQuery<ISolutions[]>(['Solutions'], () => getSolutions());
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();

  const handleSeeMore = () => {
    navigate('/realisations');
  };

  const servicesToDisplay = showAll ? nouveautes : (nouveautes || []).slice(0, 3);

  return (
    <Box bgcolor="#F7F7F7" color="back" py={0.25} px={2} textAlign="center" mt={5}>
      <Typography sx={{ marginTop:'50px'}}> </Typography>

       <Typography variant="h4" sx={{ fontFamily: 'Poppins', marginTop:'15px', fontWeight: '700' }}>
        {t('NOS REALISATIONS')}
      </Typography>
      <Typography variant='h6' sx={{ marginTop:'10px', fontFamily:'Poppins', textAlign:'center'}}>
                    {t('Découvrez ce que nous faisons pour nos précieux clients')}
                </Typography>
                <Typography sx={{ marginTop:'30px'}}> {/* Adjust marginTop */}
              
              </Typography>
      

      <Grid container spacing={2}>
        {servicesToDisplay.map((nouveaute, idx) => (
          <Grid item xs={12} sm={6} md={4} key={`news_${idx}`}>
            <NewSolution nouveaute={nouveaute} />
          </Grid>
        ))}
      </Grid>
      <Typography sx={{marginTop:'30px'}}> </Typography>
      {nouveautes && nouveautes.length > 2 && (
        <><Button variant="outlined" onClick={handleSeeMore} sx={{ mt: 5, fontFamily: 'Poppins', width: '350px', color:'white', background:'#3464AE', height:'50px',borderRadius:'8px',
        '&:hover': {
          backgroundColor: '#ADADAC',
        }, }}>
          Voir plus
        </Button><Typography sx={{marginTop:'30px'}}> </Typography></>
      )}
    </Box>
  );
};

export default Solution;
