
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import CarouselImages from 'components/ui/CarousselImages';
import { globalConfig } from 'config';
import useMainInformation from 'features/setup/services/MainInformation';
import { Box, Grid, Stack } from '@mui/material';
import HeroSection from './setup/HeroSection';
import { IIMage4Carousel } from './setup/models/MainInformation';
import { useTranslation } from 'react-i18next';
import Speciality from './setup/Speciality';
import OurValues from './setup/OurValues';
import WhyUS from './setup/WhyUs';
import Service from './setup/Service';
import Solution from './setup/Solution';
import Formulaire from './setup/Formulaire';
import Partners from './setup/Partners';



function Home() {

  const { getImages4Carousel } = useMainInformation();

  const {data: images} = useQuery<IIMage4Carousel[]>( ['ImageCarousel'], () => getImages4Carousel());
 
  const { t, i18n } = useTranslation(); 
 
  useEffect(() => {
     i18n.changeLanguage('fr');
   }, []);

  return (
    <Box sx={{ mx: 0.1 }}>
        <Grid container rowSpacing={1} columnSpacing={3}>
            <Grid item xs={12}  >
                <Stack flexDirection='column' >
                    <CarouselImages  images={ (images || []).map(x => ({
                        src: `${globalConfig.get().apiUrl}/download/${x.image_Url}`, 
                        mainTitle: x.titrePrincipal, secondaryTitle: x.titreSecondaire}) ) }  />
                               
        < Speciality/>
        < WhyUS/>  
        < OurValues/>
        < Service/>
        < Solution/>
        < Formulaire/>
        < Partners/>


       
                  </Stack>
              </Grid>
        </Grid>  
        
    </Box>       
    
  )
}

export default Home
