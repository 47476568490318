import React, { useEffect, useState } from 'react';

import { Box, Typography, Button, Stack, Divider,Container,CardMedia } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {  IContact, IPartenaires, defaultContact  } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { isFalsy } from 'utility-types';

import { globalConfig } from 'config';
import SlideImages from 'components/ui/SlideImages';
import {  typographySmallHandWriting } from 'themes/commonStyles';
import PartnersSlide from 'components/ui/PartnersSlide';

const Partners = () => {
 

  const { t, i18n } = useTranslation();  // TitreBienvenue

  const { getPartners,getContact} = useMainInformation();
  const {data: contacts} = useQuery<IContact[]>( ['Contact'], () => getContact());
  const [contact, setcontact] = useState<IContact>(defaultContact);
  const {data: slidePartners} = useQuery<IPartenaires[]>( ['Partenaires'], () => getPartners());

  
  useEffect(() => {
      
    if(!isFalsy(contacts) && contacts?.length>0)
        setcontact(contacts[0]);
  }, [contacts]);


  return (
    <Box>
    <Box bgcolor="#F7F7F7" color="back" py={0.25} px={2} textAlign="center" mt={5} sx={{  height: '300px'}}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>            
            <Stack flexDirection='column' textAlign="center" >
              <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center'}}>
              <Typography sx={{ marginTop:'40px'}}> {/* Adjust marginTop */}
        
        </Typography>
                <Typography variant="h4" sx={{fontFamily:'Poppins', fontWeight:'700'}}> 
                  {t('NOS PARTENAIRES')}
                </Typography>                
              </Box>

              <Typography sx={{marginTop:'70px'}}></Typography>
              <PartnersSlide images={ (slidePartners || []).map(x => ({ 
                        src: `${globalConfig.get().apiUrl}/download/${x.image_Url}`}) ) } />

            </Stack>
         
          </Grid>          
        </Grid>

    </Box>

    <Typography sx={{marginTop:'40px'}}></Typography>

    <Grid container spacing={1}>
  <Grid item xs={12} md={12} style={{ textAlign: 'center', position: 'relative' }}>
    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
      <img src={`${globalConfig.get().apiUrl}/download/${contact.image_Url}`} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
    </div>
    <Typography variant='h5' sx={{ fontFamily: 'Poppins', marginTop: '-185px', color: '#fff', zIndex: 1, position: 'relative',  '@media (max-width: 600px)': {width: '100%',marginTop: '-225px' } }}>{contact.titreSecondaire}</Typography>
    <Button
      variant="contained"
      color="primary"
      href="/contact"
      endIcon={<KeyboardArrowRightIcon />}
      sx={{marginTop: '20px',height: '50px',width:'20%',  '@media (max-width: 600px)': {width: '50%',marginTop: '15px' }}}
    >
      CONTACTEZ-NOUS
    </Button>
  </Grid>
</Grid>



<Typography sx={{ marginTop:'130px'}}></Typography>
    </Box>
  );
};


export default Partners;
