// Services.js

import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, Stack,Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { IContact, IServices, defaultContact } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { globalConfig } from 'config';
import { Link } from 'react-router-dom';
import { isFalsy } from 'utility-types';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';




const NewsService = ({ nouveaute }: { nouveaute: IServices }) => {
 
    return (
        <Card
            sx={{
                mt: 2,
                width: '100%',
                borderRadius: '2%',
            }}
        >
            <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>{nouveaute.titrePrincipal}</Typography>
                    <hr />
                </div>
             
            </CardContent>
            <Box
                sx={{
                    flex: 1,
                    backgroundImage: `url('${globalConfig.get().apiUrl}/download/${nouveaute.image_Url}')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    height: '200px',
                    borderRadius: '2%',
                }}
            />
            
        </Card>
    );
};

const Services = () => {
    const { t } = useTranslation();
    const { getServices,getContact} = useMainInformation();
    const {data: contacts} = useQuery<IContact[]>( ['Contact'], () => getContact());
    const [contact, setcontact] = useState<IContact>(defaultContact);

    const { data: nouveautes } = useQuery<IServices[]>(['Services'], () => getServices());

    useEffect(() => {
      
        if(!isFalsy(contacts) && contacts?.length>0)
            setcontact(contacts[0]);
      }, [contacts]);

    return (
 <Box  >

<Grid container spacing={1}>
    <Grid item xs={12} md={12} style={{ textAlign: 'center', position: 'relative' }}>
    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
      <img src={`${globalConfig.get().apiUrl}/download/${contact.image1_Url}`} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
    </div>
        <Typography variant='h4' sx={{ fontFamily: 'Poppins', marginTop: '-170px', color: '#fff', zIndex: 1, position: 'relative' }}>{contact.titreService}</Typography>
    </Grid>
</Grid>


<Box  color="back" py={0.25} px={2} textAlign="center" mt={5} >

<Typography sx={{ marginTop:'150px'}}> </Typography>

            <Typography variant="h4" sx={{ fontFamily: 'Poppins',  marginTop:'15px', fontWeight: '700' }}>
                    {t('NOS SERVICES')}
                  </Typography>

                  <Typography sx={{marginTop:'30px'}}> </Typography>

            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                {(nouveautes || []).map((nouveaute, idx) => (
                    <Grid item xs={12} sm={12} md={3} key={`news_${idx}`}>
                        <NewsService nouveaute={nouveaute} />
                    </Grid>
                ))}
            </Grid>
            </Box>

        <Typography sx={{marginTop:'60px'}}> </Typography>


        <Grid container spacing={1}>
  <Grid item xs={12} md={12} style={{ textAlign: 'center', position: 'relative' }}>
    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
      <img src={`${globalConfig.get().apiUrl}/download/${contact.image_Url}`} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
    </div>
    <Typography variant='h5' sx={{ fontFamily: 'Poppins', marginTop: '-185px', color: '#fff', zIndex: 1, position: 'relative',  '@media (max-width: 600px)': {width: '100%',marginTop: '-225px' } }}>{contact.titreSecondaire}</Typography>
    <Button
      variant="contained"
      color="primary"
      href="/contact"
      endIcon={<KeyboardArrowRightIcon />}
      sx={{marginTop: '20px',height: '50px',width:'20%',  '@media (max-width: 600px)': {width: '50%',marginTop: '15px' }}}
    >
      CONTACTEZ-NOUS
    </Button>
  </Grid>
</Grid>
     



<Typography sx={{ marginTop:'130px'}}> {/* Adjust marginTop */}
        
      </Typography>

       
        </Box>
    );
};

export default Services;
