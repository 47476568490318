import React, { useEffect, useState } from 'react';

import Box  from '@mui/material/Box';

import MenuIcon from '@mui/icons-material/Menu';

import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import Typography from '@mui/material/Typography';


import {
  flexBetweenCenter,
  dFlex,
  displayOnDesktop,
  displayOnMobile,
} from 'themes/commonStyles';

import { AppBar,  ClickAwayListener,  Divider,  Drawer,  IconButton,List,ListItem,ListItemIcon,Toolbar } from '@mui/material';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';

import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import StorefrontIcon from '@mui/icons-material/Storefront';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import PersonIcon from '@mui/icons-material/Person';



import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { isFalsy } from 'utility-types';

import { globalConfig } from 'config';

import { IMainInformation, defaultMainInformation } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';


export const HeaderMenu = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  

  const { getMainInformations } = useMainInformation();

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showPrestationsSubMenu, setShowPrestationsSubMenu] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const togglePrestationsSubMenu = () => {
    setShowPrestationsSubMenu(!showPrestationsSubMenu);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowPrestationsSubMenu(false); // Assurez-vous de masquer le sous-menu lorsque le menu principal est fermé
  };
  

  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    console.log(mainInformations);
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
}, [mainInformations]);


const [subMenuOpen, setSubMenuOpen] = useState(false);

const handleSubMenuOpen = () => {
  setSubMenuOpen(true);
};

const handleSubMenuClose = () => {
  setSubMenuOpen(false);
};


    return (
      <AppBar position="static" sx={{ backgroundColor: 'white', height:'75px' }}>
      <Toolbar>
        <Grid container alignItems="center" justifyContent={"space-around"}>
          <Grid item xs={2}>            
              <Link to="/">
                <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.logo_Url}`} alt="Logo" style={{ marginRight: '10px', width: 130, height:'75px', marginTop:'7px' }} />
              </Link>   
          </Grid>
          <Grid item xs={10} container justifyContent="space-around" sx={{ display: { xs: 'none', md: 'flex' } }}>
      <Button color="inherit" component={Link} to="/" sx={{ color: '#3464AE', fontWeight: '500', fontSize: '20px', fontFamily:'Poppins', '&:hover': { color: '#3464AE' }}}>{t('Home')}</Button>
      <Button color="inherit" component={Link} to="/aboutUs" sx={{ color: '#ADADAC', fontWeight: '500', fontSize: '20px', fontFamily:'Poppins', '&:hover': { color: '#3464AE' } }}>{t(" A propos de nous")}</Button>
      <Button color="inherit" component={Link} to="/galery" sx={{ color: '#ADADAC', fontWeight: '500', fontSize: '20px', fontFamily:'Poppins', '&:hover': { color: '#3464AE' } }}>{t(" Galerie")}</Button>

      <Button
      component={Link} to="/services"
  color="inherit"
  onMouseEnter={handleSubMenuOpen}
  onMouseLeave={handleSubMenuClose}
  sx={{
    
    color: '#ADADAC',
    fontWeight: '500',
    fontSize: '20px', 
    fontFamily:'Poppins',
    '&:hover': { color: '#3464AE' }
  }}
>
  {t('Nos Services')}
 
</Button>
<Button color="inherit" component={Link} to="/realisations" sx={{ color: '#ADADAC', fontWeight: '500', fontSize: '20px', fontFamily:'Poppins', '&:hover': { color: '#3464AE' }}}>{t('Nos réalisations')}</Button>
      <Button color="inherit" component={Link} to="/contact" sx={{ color: '#ADADAC', fontWeight: '500', fontSize: '20px', fontFamily:'Poppins', '&:hover': { color: '#3464AE' }}}>{t('Contact')}</Button>
    </Grid>  

    <Grid item xs={10} container justifyContent="flex-end" sx={{ display: { xs: 'flex', md: 'none' } }}>
      <IconButton onClick={handleClick} edge="start" sx={{ color: '#000', fontSize: '45px' }} aria-label="menu">
        <MenuIcon sx={{ color: '#000', fontSize: '55px' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => { navigate('/'); handleClose(); }}>
          <Typography variant="body1" fontWeight="bold" color="#3464AE">Accueil</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => { navigate('aboutUs'); handleClose(); }}>
          <Typography variant="body1" fontWeight="bold" color="#ADADAC">A propos de nous</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => { navigate('galery'); handleClose(); }}>
          <Typography variant="body1" fontWeight="bold" color="#ADADAC">Galerie</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => { navigate('services'); handleClose(); }}>
          <Typography variant="body1" fontWeight="bold" color="#ADADAC">Nos Services</Typography>
        </MenuItem>
        <Divider />
        
        <MenuItem onClick={() => { navigate('solutions'); handleClose(); }}>
          <Typography variant="body1" fontWeight="bold" color="#ADADAC">Nos Réalisations</Typography>
        </MenuItem>
        <Divider />
      
        <MenuItem onClick={() => { navigate('contact'); handleClose(); }}>
          <Typography variant="body1" fontWeight="bold" color="#ADADAC">Contact</Typography>
        </MenuItem>
        <Divider />
        
      </Menu>
    </Grid>

        </Grid>
        
      </Toolbar>
    </AppBar>
    );
  }
