import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { IAboutPage, IContact, defaultAboutPage, defaultContact} from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { isFalsy } from 'utility-types';
import { globalConfig } from 'config';
import { Box, Grid, Typography, Container, Card, IconButton,TextField,Button } from '@mui/material';





const About = () => {

    const { getContact, getAboutPage } = useMainInformation();

    const {data: contacts} = useQuery<IContact[]>( ['Contact'], () => getContact());
    const [contact, setcontact] = useState<IContact>(defaultContact);

    const {data: abouts} = useQuery<IAboutPage[]>( ['AboutPage'], () => getAboutPage());
    const [about, setAbout] = useState<IAboutPage>(defaultAboutPage);

    useEffect(() => {
      
        if(!isFalsy(contacts) && contacts?.length>0)
            setcontact(contacts[0]);
      }, [contacts]);


      useEffect(() => {
      
        if(!isFalsy(abouts) && abouts?.length>0)
            setAbout(abouts[0]);
      }, [abouts]);

    return (
        <Box >
        
        <Grid container spacing={1}>
    <Grid item xs={12} md={12} style={{ textAlign: 'center', position: 'relative' }}>
    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
      <img src={`${globalConfig.get().apiUrl}/download/${contact.image1_Url}`} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
    </div>
        <Typography variant='h4' sx={{ fontFamily: 'Poppins', marginTop: '-170px', color: '#fff', zIndex: 1, position: 'relative' }}>{contact.aPropos}</Typography>
    </Grid>
</Grid>


<Container maxWidth='xl' sx={{ marginTop:'150px'}}>
  <Grid container spacing={1} style={{ padding: '20px', position: 'relative' }}>
    <Grid item xs={12} md={12} style={{ textAlign: 'justify', padding: '20px', position: 'relative'}}>
      
      
        <Typography variant='h6' sx={{ fontFamily: 'Poppins', marginTop: '30px' }}> <span style={{fontWeight:'700', color:'#3464AE'}}>{about.titreGlobal}</span >  {about.titrePrincipal}</Typography>
        <Typography variant='h6' sx={{ fontFamily: 'Poppins' }}><span style={{fontWeight:'700', color:'#3464AE'}}>{about.titreGlobal}</span>  {about.titreSecondaire} </Typography>
        <Typography variant='h6' sx={{ fontFamily: 'Poppins' }}>{about.description}</Typography>

      

    

    </Grid>
    </Grid>
    </Container>


<Typography sx={{ marginTop:'30px'}}> {/* Adjust marginTop */}
        
        </Typography>
  
        
  
  
        <Grid container spacing={1}>
    <Grid item xs={12} md={12} style={{ textAlign: 'center', position: 'relative' }}>
      <div style={{ position: 'relative', width: '100%', height: '300px' }}>
        <img src={`${globalConfig.get().apiUrl}/download/${contact.image_Url}`} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
      </div>
      <Typography variant='h5' sx={{ fontFamily: 'Poppins', marginTop: '-185px', color: '#fff', zIndex: 1, position: 'relative',  '@media (max-width: 600px)': {width: '100%',marginTop: '-225px' } }}>{contact.titreSecondaire}</Typography>
      <Button
        variant="contained"
        color="primary"
        href="/contact"
        endIcon={<KeyboardArrowRightIcon />}
        sx={{marginTop: '20px',height: '50px',width:'20%',  '@media (max-width: 600px)': {width: '50%',marginTop: '15px' }}}
      >
        CONTACTEZ-NOUS
      </Button>
    </Grid>
  </Grid>
  
  
  
  <Typography sx={{ marginTop:'130px'}}> {/* Adjust marginTop */}
          
        </Typography>
      </Box>       

    );
}

export default About;
