
export interface IMainInformation {
    id: number, 
    portable: string,
    logo: string,
    logo_Url: string,
    email: string,
    horaire: string,
    localisation:string,
    lienFacebook : string,
    lienLinkedin : string,
    description :string
}

export interface IContact{
    id : number,
    titre : string,
    image : string,
    image_Url : string,
    image1: string,
    image1_Url : string,
    titreSecondaire : string,
    aPropos : string,
    titreService:string,
    titreGalerie:string,
    titreRealisation:string
}

export interface IAboutPage{
    id:number,
    titreGlobal : string,
    titrePrincipal : string,
    titreSecondaire : string,
    description : string
}

export interface IHeroSection {
    id: number, 
    titreGlobal: string,
    image: string,
    image_Url: string,
    titre: string,
    titrePrincipal: string,
    description : string,
      
}

export interface IIMage4Carousel {
    id: number,
    image: string, 
    image_Url: string, 
    titrePrincipal: string, 
    titreSecondaire: string,
  
}

export interface ISpecialyty {
    id: number,
    videoId: string, 
    titrePrincipal: string, 
    titreSecondaire: string,
    specialite1 : string,
    specialite2 : string,
    specialite3 : string,
    specialite4 : string,
    specialite5 : string,

  
}

export interface IValues {
    id:number,
    titre:string,
    description:string
}

export interface IWhyUS{
    id:number,
    titreGlobal:string,
    titrePrincipal : string,
    image:string,
    image_Url:string,
    icone:string,
    icone_Url:string

}

export interface IServices {
    id: number,
    titrePrincipal: string,   
    image: string,
    image_Url: string 
}

export interface ISolutions {
    id: number,
    titrePrincipal: string,   
    description:string
}

export interface IPartenaires {
    id: number,
    image: string, 
    image_Url: string
    
  }

  export interface IGalery{
    id: number,
    image: string,
    image_Url: string 
}

export const defaultMainInformation : IMainInformation = {
    id: 0,
    portable: "",
    logo: "",
    logo_Url: "",
    email: "",
    horaire: "",
    lienFacebook: "",
    lienLinkedin: "",
    localisation: "",
    description: ""
}

export const defaultHeroSection : IHeroSection = {
    id: 0,
    titreGlobal: "",
    image: "",
    image_Url: "",
    titre: "",
    titrePrincipal: "",
    description: ""
}

export const defaultContact : IContact = {
    id: 0,
    titre: "",
    image: "",
    image_Url: "",
    image1: "",
    image1_Url: "",
    titreSecondaire: "",
    aPropos: "",
    titreService: "",
    titreGalerie: "",
    titreRealisation: ""
}

export const defaultAboutPage : IAboutPage = {
    id: 0,
    titreGlobal: "",
    titrePrincipal: "",
    titreSecondaire: "",
    description: ""
}

export const defaultSpeciality : ISpecialyty = {
    id: 0,
    videoId: "",
    titrePrincipal: "",
    titreSecondaire: "",
    specialite1: "",
    specialite2: "",
    specialite3: "",
    specialite4: "",
    specialite5: ""
}