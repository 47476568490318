import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Container, Grid, Typography, Card, CardContent, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMainInformation from 'features/setup/services/MainInformation';
import { IWhyUS } from './models/MainInformation';
import { globalConfig } from 'config';

const WhyUS = () => {
    const { t } = useTranslation();

    const { getWhyUS } = useMainInformation();
    const { data: whyInformations } = useQuery<IWhyUS[]>(['WhyUS'], () => getWhyUS());
    const [whys, setWhyInformations] = useState<IWhyUS[]>([]);
    const [hoveredCard, setHoveredCard] = useState<number | null>(null);

    useEffect(() => {
        setWhyInformations(whyInformations || []);
    }, [whyInformations]);

    const handleMouseEnter = (index: number) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    return (
        <Box sx={{backgroundColor:'', height:'100%'}}>
            <Container maxWidth="xl">
                <Typography variant='h4' sx={{ marginTop:'30px', fontFamily:'Poppins', textAlign:'center', fontWeight: 'bold'}}>
                    {t('POURQUOI NOUS CHOISIR ?')}
                </Typography>
                <Typography variant='h6' sx={{ marginTop:'10px', fontFamily:'Poppins', textAlign:'center'}}>
                    {t('Parce que nous vous offrons des solutions sur mesure et adéquate pour vous permettre de mieux atteindre vos objectifs ?')}
                </Typography>
                <Typography sx={{ marginTop:'30px'}}> {/* Adjust marginTop */}
              
              </Typography>
                <Grid container spacing={1}>
                    {whys.map((val, index) => (
                        <Grid item key={index} xs={12} md={4}>
                            <Card 
                                sx={{
                                    height:'300px',
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${globalConfig.get().apiUrl}/download/${val.image_Url}')`,
                                    backgroundSize: 'cover',
                                    position: 'relative',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: hoveredCard === index ? '#3464AE' : 'transparent',
                                        opacity: hoveredCard === index ? 1 : 0,
                                    },
                                    '&:hover::after': {
                                        opacity: 1,
                                    },
                                }}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
                                    <img src={`${globalConfig.get().apiUrl}/download/${val.icone_Url}`} alt="Icon" className="icon" style={{ width: '70px', height: '70px', margin: '0 auto', position: 'relative', zIndex: 1, marginTop:'10px' }} />
                                    <Typography variant="h6" className="title" sx={{fontFamily:'Poppins', fontWeight:'700', textAlign:'justify', marginTop: '15px', color: hoveredCard === index ? '#fff' : '#fff', position: 'relative', zIndex: 1}}>{val.titreGlobal}</Typography>
                                    <Typography variant="body1" className="description" sx={{fontFamily:'Poppins',  textAlign:'justify', color: hoveredCard === index ? '#fff' : '#fff', position: 'relative', zIndex: 1}}>{val.titrePrincipal}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Typography sx={{ marginTop:'15px'}}> {/* Adjust marginTop */}
              
                </Typography>
            </Container>
            <Typography sx={{ marginTop:'30px'}}> {/* Adjust marginTop */}
              
            </Typography>
        </Box>
    );
};

export default WhyUS;
