import React, {useState,useRef, useEffect} from 'react';

import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { useNavigate } from 'react-router-dom';

import {  useRecoilValue, useRecoilState } from 'recoil';

import {Language as LanguageIcon, TranslateOutlined as TranslateOutlinedIcon,  } from '@mui/icons-material';

import { BsGlobe } from 'react-icons/bs';
import { IoChevronUpOutline } from 'react-icons/io5';
import { FaFileExport } from 'react-icons/fa';
import { TbLayoutDashboard } from 'react-icons/tb';


import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import Popper from '@mui/material/Popper';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon  from '@mui/icons-material/Instagram';
import TwitterIcon  from '@mui/icons-material/Twitter';
import LinkedInIcon  from '@mui/icons-material/LinkedIn';

import ClickAwayListener from '@mui/material/ClickAwayListener';

import { flexBetweenCenter, fullWidthFlex, justifyCenter, typographyBigGroupBoxStyling, typographyGroupBoxStyling } from 'themes/commonStyles';

import { isExportBoxShowAtom, isDesktopPublishingBoxShowAtom ,currentUserSessionAtom } from 'library/store';
import { Divider, Grid, TextField, Typography } from '@mui/material';

import { globalConfig } from 'config';

import useUtils from 'library/utils';

import {  IMainInformation, defaultMainInformation } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { useQuery } from 'react-query';
import { isFalsy } from 'utility-types';
import { Link } from 'react-router-dom';



export const Footer = () => {


  const { getMainInformations } = useMainInformation();

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());
 

  const {capitalizeFirstLetter, range} = useUtils();

  const { t, i18n } = useTranslation(); 

  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    
      if(!isFalsy(mainInformations) && mainInformations?.length>0)
        setMainInformation(mainInformations[0]);
}, [mainInformations]);


const [email, setEmail] = useState('');

const handleEmailChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
  setEmail(e.target.value);
};

const handleSubmit = (e: { preventDefault: () => void; }) => {
  e.preventDefault();
  console.log(email);
  // Ici, vous pouvez ajouter votre logique pour envoyer l'email de la newsletter
  setEmail('');
};
  

  return (
    <Box
      sx={{
        ...fullWidthFlex,
        borderTop: '1px solid #ddd',
        background: '#333'
      }}
    >
      <Grid container rowSpacing={0.5} columnSpacing={0.1}>
      <Grid item xs={12} sm={6} md={4} lg={3} sx={{ borderRadius: 2, ml: 0 }}>
  <Stack flexDirection='column' alignItems='center'>
    <Box sx={{ mt: 0.25, width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Link style={{ fontFamily: 'Poppins', textDecoration: 'none', marginRight: '25px' }} to='/'>
        <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.logo_Url}`} alt="Logo" style={{ width: 150, borderRadius: '5%' }} />
      </Link>
    </Box>
    <Box sx={{ mt: 0.25, width: '100%' }}>
      <Typography
        variant='body1'
        sx={{
          fontFamily: 'Poppins',
          textAlign:'justify',
          color: 'white',
          whiteSpace: 'normal',  // Permettre les retours à la ligne automatiques
          wordWrap: 'break-word' // Permettre la coupure des mots si nécessaire
        }}
        id="tableTitle"
        noWrap={false} // Désactiver noWrap pour permettre le retour à la ligne
      >
        {mainInformation.description}
      </Typography>
    </Box>
  </Stack>
</Grid>
        
<Typography sx={{ marginTop: '50px' }}></Typography>
       
        <Grid item xs={12} sm={12} md={4} lg={3} sx={{ borderRadius: 2, ml: 0, }} >
          <Stack flexDirection='column' alignItems='center' 
        justifyContent='center' sx={{ height: '100%', marginTop:'-18px' }} >
            <Box sx={{ mt: 0.25, width: '100%', textAlign: 'center' }} >
          <Typography variant='h5' sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }} id="tableTitle" color="white" noWrap>
            {t('NAVIGATION')}
          </Typography>
        </Box>

        <Typography sx={{ marginTop: '6px' }}></Typography>

        <Box sx={{ mt: 0.25, width: '100%', textAlign: 'center' }} >
          <Link to="/" style={{ fontFamily: 'Poppins', fontWeight: '500', color: 'white', textDecoration: 'none' }}>
            {t('Accueil')}
          </Link>
        </Box>

        <Box sx={{ mt: 0.25, width: '100%', textAlign: 'center' }} >
          <Link to="/aboutUS" style={{ fontFamily: 'Poppins', fontWeight: '500', color: 'white', textDecoration: 'none' }}>
            {t('A propos de nous')}
          </Link>
        </Box>

        <Box sx={{ mt: 0.25, width: '100%', textAlign: 'center' }} >
          <Link to='/galery' style={{ fontFamily: 'Poppins', fontWeight: '500', color: 'white', textDecoration: 'none' }}>
            {t('Galerie')}
          </Link>
        </Box>

        <Box sx={{ mt: 0.25, width: '100%', textAlign: 'center' }} >
          <Link to='/services' style={{ fontFamily: 'Poppins', fontWeight: '500', color: 'white', textDecoration: 'none' }}>
            {t('Nos Services')}
          </Link>
        </Box>

        <Box sx={{ mt: 0.25, width: '100%', textAlign: 'center' }} >
          <Link to='/realisations' style={{ fontFamily: 'Poppins', fontWeight: '500', color: 'white', textDecoration: 'none' }}>
            {t('Nos realisations')}
          </Link>
        </Box>

        <Box sx={{ mt: 0.25, width: '100%', textAlign: 'center' }} >
          <Link to='/contact' style={{ fontFamily: 'Poppins', fontWeight: '500', color: 'white', textDecoration: 'none' }}>
            {t('Contact')}
          </Link>
        </Box>
          </Stack>
        </Grid>
        
        <Grid item xs={12} sm={12} md={4} lg={3} sx={{ borderRadius: 2, ml: 0, }} >
          <Stack flexDirection='column'  >
            <Box sx={{ mt: 0.25, width: '100%', textAlign: 'center' }} > 
              <Typography  variant='h5'  sx={{ fontFamily: 'Poppins', fontWeight:'bold',color: 'white' }} >
                {`${t(('NEWSLETTER'))} `}
              </Typography>                                                       
            </Box>
            <Typography sx={{color:'#fff', textAlign:'justify'}}>Souscrivez à notre Newsletter pour rester informer de nos nouveaux offres de services</Typography>
            <Typography sx={{marginTop:'6px'}}></Typography>
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField
              fullWidth
              placeholder='Votre email'
              variant='outlined'
              value={email}
              onChange={handleEmailChange}
              sx={{
                mb: 2,
                backgroundColor: 'white', // Couleur de fond blanche pour le champ de saisie
                borderRadius: '4px',
              }}
            />
            <Button
              type='submit'
              variant='contained'
              color='primary'
              sx={{
                height: '50px',
                width: '100%',
                fontFamily: 'Poppins',
                backgroundColor: '#3464AE', // Couleur personnalisée pour le bouton
                '&:hover': {
                  backgroundColor: '#ADADAC', // Couleur au survol pour le bouton
                },
              }}
            >
              {t('S\'inscrire')}
            </Button>
          </Box>
        </form>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={3} sx={{ borderRadius: 2, ml: 0, }} >
          <Stack flexDirection='column'  >
            <Box sx={{ mt: 0.25, width: '100%', textAlign: 'center' }} > 
              <Typography  variant='h5'  sx={{ fontFamily: 'Poppins', fontWeight:'bold' }} id="tableTitle" color="white" noWrap >
                {`${t(('CONTACT'))} `}
              </Typography>                                                       
            </Box>

            <Typography sx={{marginTop:'6px'}}></Typography>

            <Box sx={{ mt: 0.25, width: '100%' }} > 
              <Typography component="h3"  sx={{ fontFamily:'Poppins', fontWeight:'500', textAlign: 'center'}}  id="tableTitle" color="white" noWrap >
               Horaire : {mainInformation.horaire}
              </Typography>   
            </Box>
            <Box sx={{ mt: 0.25, width: '100%' }} > 
              <Typography component="h3" sx={{ fontFamily:'Poppins', fontWeight:'500', textAlign: 'center'}} id="tableTitle" color="white" noWrap >
                Téléphone :{mainInformation.portable}
              </Typography>   
            </Box>
            <Box sx={{ mt: 0.25, width: '100%' }} > 
              <Typography component="h3"  sx={{ fontFamily:'Poppins', fontWeight:'500', textAlign: 'center'}}  id="tableTitle" color="white" noWrap >
                Email : {mainInformation.email}
              </Typography>   
            </Box>
            <Box sx={{ mt: 0.25, width: '100%' }} > 
              <Typography component="h3"  sx={{ fontFamily:'Poppins', fontWeight:'500', textAlign: 'center'}} id="tableTitle" color="white" >
                {mainInformation.localisation}
              </Typography>   
            </Box>
            <Box sx={{ mt: 0.25, width: '100%' , textAlign: 'center'}} > 
            <Typography>
                <Link to='#' target="_blank" style={{ textDecoration: 'none', color: '#3b5998', marginRight: '30px' }}>
                    <FacebookIcon sx={{ fontSize: '25px' }} />
                </Link>
                <Link to='#' target="_blank" style={{ textDecoration: 'none', color: '#e4405f', marginRight: '30px' }}>
                    <InstagramIcon sx={{ fontSize: '25px' }} />
                </Link>
                <Link to='#' target="_blank" style={{ textDecoration: 'none', color: '#1da1f2', marginRight: '30px' }}>
                    <TwitterIcon sx={{ fontSize: '25px' }} />
                </Link>
                <Link to='#' target="_blank" style={{ textDecoration: 'none', color: '#0077b5' }}>
                    <LinkedInIcon sx={{ fontSize: '25px' }} />
                </Link>
            </Typography> 
            </Box>
          </Stack>
        </Grid>
        <Divider />
        <Grid item xs={12} sx={{ borderRadius: 2, ml: 0, }} >
          <Stack flexDirection='column'  textAlign="center">
            <Box sx={{ mt: 0.25, width: '100%' }} > 
              <Typography component="h3"  sx={{ fontFamily:'Poppins', fontWeight:'500'}}  id="tableTitle" color="white" noWrap >
                {`${t(('Copyright © 2024 Univsoft tous droits réservés'))} `}
              </Typography>                                                       
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}
