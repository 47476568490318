import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Container, Grid, Typography, Card, CardContent, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMainInformation from 'features/setup/services/MainInformation';
import { IValues } from './models/MainInformation';

const OurValues = () => {
    const { t } = useTranslation();

    const { getValues } = useMainInformation();
    const { data: values } = useQuery<IValues[]>(['Values'], () => getValues ());
    const [value, setValues] = useState<IValues[]>([]);
    
    useEffect(() => {
        setValues(values || []);
    }, [values]);

    return (
        <Box sx={{backgroundColor:'#f9f9f9', height:'100%'}}>
            <Container maxWidth="xl">
                <Typography sx={{ marginTop:'30px'}}> {/* Adjust marginTop */}
              
                </Typography>
                <Typography variant='h4' sx={{ fontFamily: 'Poppins', textAlign:'center', fontWeight: 'bold' }} noWrap>
                    {t('NOS VALEURS')}
                </Typography>
                <Typography sx={{ marginTop:'30px'}}> {/* Adjust marginTop */}
              
                </Typography>
                <Grid container spacing={3}>
                    {/* Afficher chaque valeur en tant que carte */}
                    {value.map((val, index) => (
                        <Grid item key={index} xs={12} md={4}>
                            <Card sx={{
                                height:'250px',
                                '&:hover': {
                                    backgroundColor: '#3464AE',
                                    color: '#fff',
                                }
                            }}>
                                <CardContent>
                                    <Typography variant="h5" sx={{fontFamily:'Poppins', fontWeight:'700', textAlign:'center'}}>{val.titre}</Typography>
                                    <Typography variant="h6" sx={{fontFamily:'Poppins', textAlign:'justify'}} >{val.description}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Typography sx={{ marginTop:'15px'}}> {/* Adjust marginTop */}
              
                </Typography>
            </Container>
            <Typography sx={{ marginTop:'30px'}}> {/* Adjust marginTop */}
              
            </Typography>
        </Box>
    );
};

export default OurValues;
